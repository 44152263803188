import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/codebuild/output/src4157170493/src/craft-stadium-client/node_modules/@nextui-org/link/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4157170493/src/craft-stadium-client/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["PlayerNavbarClient"] */ "/codebuild/output/src4157170493/src/craft-stadium-client/src/app/(service)/_components/PlayerNavbarClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserStatus"] */ "/codebuild/output/src4157170493/src/craft-stadium-client/src/app/(service)/_components/UserStatus.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorCard"] */ "/codebuild/output/src4157170493/src/craft-stadium-client/src/components/ErrorCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeSwitch"] */ "/codebuild/output/src4157170493/src/craft-stadium-client/src/components/theme-switch.tsx");
